
export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'email',
    label: '',
    Object: 'value',
    width: '150'
  }]
}
